import React, { useEffect, useState, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Button,
  Container,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
  Typography,
  Input,
  InputLabel,
  TextareaAutosize,
  TextField,
  MenuItem,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { useCampaigns } from "../../utils/campaigns";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useParams } from "react-router-dom";
import FormSection from "../../ui/forms/input/FormSection";
import { campaign, common, form, navigation } from "../../messages";
import { DatePicker, TimePicker } from "../../ui/DatePicker";
import { getErrorMessageString } from "../../utils/errors";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { PERIOD_TYPE } from "../../utils/campaignMessagesConstants";
import { intlFormatMessage } from "../../utils/utils";
import { format, startOfDay } from "date-fns";
import { parseBmarkenDate } from "../../utils/utils";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";

function Navigation() {
  const intl = useIntl();

  return (
    <FormControl margin="normal" fullWidth>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item>
          <Button
            component={RouterLink}
            to={MANAGER_ROUTES.MESSAGES_CAMPAIGNS.replace(":campaignId", "")}
            startIcon={<ArrowBack />}
          >
            {intl.formatMessage(common.backMessages)}
          </Button>
        </Grid>
        <Grid item>
          <Button
            component={RouterLink}
            to={MANAGER_ROUTES.HOME}
            startIcon={<ArrowForward />}
          >
            {intl.formatMessage(navigation.goHome)}
          </Button>
        </Grid>
      </Grid>
    </FormControl>
  );
}

function initValues() {
  return { /*campaignName: "", */ title: "", body: "" };
}
function initDistrValues() {
  return { listId: "" };
}

const lists = [
  { id: "ALL", name: "Tutti gli utenti" },
  { id: "DIR-MARKETING", name: "Consenso Marketing fornito" },
];

export default function Campaigns() {
  const {
    bmapi,
    businessId,
    startLoading,
    stopLoading,
    notifySuccess,
    notifyError,
  } = useBmapi();
  const history = useHistory();
  const { campaigns, loadCampaigns } = useCampaigns();
  const { messageId } = useParams();
  const [values, setValues] = useState(initValues());
  const [distrValues, setDistrValues] = useState(initDistrValues());
  const intl = useIntl();
  const today = new Date();
  const [campaignSelected, setCampaignSelected] = React.useState(null);
  const [messageStatus, setMessageStatus] = React.useState(null);
  const [distributions, setDistributions] = React.useState([]);
  const clone = location.href.includes("clone");

  useEffect(() => {
    !campaigns ? startLoading() : stopLoading();
  }, [campaigns, startLoading, stopLoading]);

  useEffect(() => {
    if (campaigns) {
      campaigns.push({
        name: intl.formatMessage(campaign.noCampaign),
        campaign_id: "-",
        business_name: "",
        isActive: true,
      });
      bmapi
        .getMessagesCampaign()
        .then((messages) => {
          setMessageStatus(messages[0].message.status);
          const msgObj = messages.find((x) => x.message.id == messageId);
          const campaignId =
            campaigns.find(
              (x) => x.campaign_id == msgObj.message.campaign_id
            ) == undefined
              ? "-"
              : campaigns.find(
                  (x) => x.campaign_id == msgObj.message.campaign_id
                ).campaign_id;
          setValues({
            /*campaignName:
              campaigns.find(
                (x) => x.campaign_id == msgObj.message.campaign_id
              ) == undefined
                ? "Nessuna campagna"
                : campaigns.find(
                    (x) => x.campaign_id == msgObj.message.campaign_id
                  ).name,*/
            campaign_id: campaignId,
            title: msgObj.message.title,
            body: msgObj.message.body,
            banner: msgObj.mobile_config.banner,
            banner_close: !!msgObj.mobile_config.banner_close_mode,
            banner_close_mode: msgObj.mobile_config.banner_close_mode,
            /*banner_close_date: msgObj.mobile_config.banner_close_date?.substring(
              0,
              16
            ),*/
            banner_close_date: parseBmarkenDate(
              msgObj.mobile_config.banner_close_date
            ),
            close_date: parseBmarkenDate(
              msgObj.mobile_config.banner_close_date
            ),
            close_time: parseBmarkenDate(
              msgObj.mobile_config.banner_close_date
            ),
            banner_close_number: msgObj.mobile_config.banner_close_number || "",
            push_notification: msgObj.mobile_config.push_notification,
            expire_at: msgObj.message.expire_at.substring(0, 10),
            app_link_label: msgObj.message.app_link_label,
            app_link_uri: msgObj.message.app_link_uri,
            image_url: msgObj.message.image_url,
            link_uri: msgObj.mobile_config.link_uri,
          });
          setCampaignSelected(campaignId);
          stopLoading();
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
          stopLoading();
        });
    }
    // eslint-disable-next-line
  }, [campaigns]);

  const handleChange = useCallback((label) => {
    const updateValue = (val) => {
      setValues((v) => ({ ...v, [label]: val }));
    };

    return (i, f) => {
      if (typeof f === "boolean") {
        if ((label === "banner" || label === "banner_close") && !f) {
          updateValue(false);
          setValues((v) => ({
            ...v,
            banner_close: false,
            banner_close_mode: "DAY",
            //banner_close_date: format(new Date(), "yyyy-MM-dd"),
            /*banner_close_date: format(new Date(), "yyyy-MM-dd00:00").replace(
              /(\d{4}-\d{2}-\d{2})(\d{2}:\d{2})/,
              "$1T$2"
            ),*/
            banner_close_date: "",
            close_date: new Date(),
            close_time: startOfDay(new Date()),
            banner_close_number: 1,
          }));
        } else {
          updateValue(f);
        }
      } else if (i?.target) {
        updateValue(i.target.value);
      } else {
        updateValue(i);
      }
    };
  }, []);

  const handleDistrChange = useCallback((label) => {
    const updateValue = (val) => {
      setDistrValues((v) => ({ ...v, [label]: val }));
    };
    return (i) => {
      if (label === "listId") {
        updateValue(i.target.value);
      } else {
        updateValue(i);
      }
    };
  }, []);

  const handleChangeCampaign = (event) => {
    setCampaignSelected(event.target.value);
  };

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  const isDate = values.banner_close_mode === "DAY";

  console.log(values);

  function fields() {
    return (
      <div style={{ minHeight: "300px" }}>
        <FormControl fullWidth margin="normal">
          <InputLabel>{intl.formatMessage(form.title)}</InputLabel>
          <Input
            name="title"
            type="text"
            value={values.title}
            onChange={handleChange("title")}
            key="title"
            fullWidth
            required
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <Typography className="MuiFormLabel-root" gutterBottom>
            {intl.formatMessage(common.text)}
          </Typography>
          <TextareaAutosize
            name="body"
            value={values.body}
            onChange={handleChange("body")}
            key="body"
            style={{
              width: "100%",
              height: "200px",
            }}
            aria-label="maximum height"
            placeholder={intl.formatMessage(form.insertMessage)}
          />
        </FormControl>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.banner ? "checked" : ""}
                onChange={handleChange("banner")}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={intl.formatMessage(form.banner)}
          />
          {values.banner && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.banner_close}
                  onChange={handleChange("banner_close")}
                />
              }
              label={intl.formatMessage(form.bannerClose)}
            />
          )}
        </FormGroup>
        {values.banner_close && (
          <>
            <TextField
              margin="normal"
              autoFocus
              label={intl.formatMessage(form.selectPeriod)}
              value={values.banner_close_mode}
              onChange={handleChange("banner_close_mode")}
              fullWidth
              select
              InputLabelProps={{
                shrink: true,
              }}
            >
              {PERIOD_TYPE.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {intlFormatMessage(common[option.label], option.label, intl)}
                </MenuItem>
              ))}
            </TextField>
            {isDate ? (
              <>
                <DatePicker
                  label={intl.formatMessage(form.selectDate)}
                  value={values.close_date}
                  onChange={handleChange("close_date")}
                  fullWidth
                  margin="normal"
                  required
                  clearable
                />
                <TimePicker
                  label={intl.formatMessage(form.selectTime)}
                  value={values.close_time}
                  onChange={handleChange("close_time")}
                  fullWidth
                  margin="normal"
                  clearable
                />
              </>
            ) : (
              <TextField
                required
                margin="normal"
                label={intl.formatMessage(form.insertQuantity)}
                fullWidth
                autoFocus
                type="number"
                value={values.banner_close_number}
                onChange={handleChange("banner_close_number")}
                onInput={(e) => {
                  e.target.value =
                    e.target.value !== ""
                      ? e.target.value === "0"
                        ? e.target.value.replace(/^[0]|[^0-9]/g, "")
                        : +e.target.value.replace(/^[0]|[^0-9]/g, "")
                      : e.target.value;
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: "1",
                }}
              />
            )}
          </>
        )}
        {/*
        {values.banner_close && (
          <Box display="flex">
            <TextField
              margin="normal"
              autoFocus
              label={intl.formatMessage(form.selectPeriod)}
              value={values.banner_close_mode}
              onChange={handleChange("banner_close_mode")}
              fullWidth
              select
              InputLabelProps={{
                shrink: true,
              }}
            >
              {PERIOD_TYPE.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {intlFormatMessage(common[option.label], option.label, intl)}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              required
              margin="normal"
              label={
                isDate
                  ? intl.formatMessage(form.selectDate)
                  : intl.formatMessage(form.insertQuantity)
              }
              fullWidth
              autoFocus
              type={isDate ? "datetime-local" : "number"}
              value={
                isDate ? values.banner_close_date : values.banner_close_number
              }
              onChange={
                isDate
                  ? handleChange("banner_close_date")
                  : handleChange("banner_close_number")
              }
              onInput={
                isDate
                  ? false
                  : (e) => {
                      e.target.value =
                        e.target.value !== ""
                          ? e.target.value === "0"
                            ? e.target.value.replace(/^[0]|[^0-9]/g, "")
                            : +e.target.value.replace(/^[0]|[^0-9]/g, "")
                          : e.target.value;
                    }
              }
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: "1",
              }}
            />
          </Box>
        )}
        */}

        <FormControlLabel
          control={
            <Checkbox
              checked={values.push_notification ? "checked" : ""}
              onChange={handleChange("push_notification")}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={intl.formatMessage(form.pushNotification)}
        />
        <DatePicker
          minDate={today}
          margin="dense"
          fullWidth
          label="Expire At"
          format="yyyy-MM-dd"
          value={values.expire_at === "0001-01-01" ? null : values.expire_at}
          onChange={handleChange("expire_at")}
        />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="link-url-content"
            id="link-url-header"
          >
            <Typography>
              {intl.formatMessage({
                id: "label.linkAndUrl",
                defaultMessage: "Link e URL",
              })}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: "column" }}>
            <FormControl fullWidth margin="dense">
              <InputLabel>App Link Label</InputLabel>
              <Input
                name="app_link_label"
                value={values.app_link_label}
                onChange={handleChange("app_link_label")}
                key="app_link_label"
              />
            </FormControl>
            <FormControl fullWidth margin="dense">
              <InputLabel>App Link URI</InputLabel>
              <Input
                name="app_link_uri"
                value={values.app_link_uri}
                onChange={handleChange("app_link_uri")}
                key="app_link_uri"
              />
            </FormControl>
            <FormControl fullWidth margin="dense">
              <InputLabel>Image URL</InputLabel>
              <Input
                name="image_url"
                value={values.image_url}
                onChange={handleChange("image_url")}
                key="image_url"
              />
            </FormControl>
            <FormControl fullWidth margin="dense">
              <InputLabel>Link URI</InputLabel>
              <Input
                name="link_uri"
                value={values.link_uri}
                onChange={handleChange("link_uri")}
                key="link_uri"
              />
            </FormControl>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    const client_id = "16d4c11d-1798-4217-8834-d0fe37a4c49c";
    const campaign_id = campaignSelected;

    if (!values.banner_close) {
      values.banner_close_mode = "";
      values.banner_close_date = "";
      values.banner_close_number = "";
    } else {
      if (values.close_time) {
        values.banner_close_date = `${format(
          values.close_date,
          "yyyy-MM-dd"
        )}T${format(values.close_time, "HH:mm:00")}`;
      } else {
        values.banner_close_date = `${format(
          values.close_date,
          "yyyy-MM-dd"
        )}T00:00:00`;
      }
    }

    delete values.close_date;
    delete values.close_time;

    if (!clone) {
      return bmapi
        .updateMessageCampaign(
          values.title,
          values.body,
          values.banner,
          values.banner_close_mode,
          values.banner_close_mode === "DAY" ? values.banner_close_date : "",
          values.banner_close_mode === "DAY" ? 0 : +values.banner_close_number,
          values.push_notification,
          values.expire_at instanceof Date
            ? values.expire_at.toISOString().substring(0, 10)
            : values.expire_at,
          values.app_link_label,
          values.app_link_uri,
          values.image_url,
          values.link_uri,
          messageId,
          client_id,
          campaign_id === "-" ? "" : campaign_id
        )
        .then(() => {
          notifySuccess(
            intl.formatMessage({
              id: "pages.messageCampaign.update",
              defaultMessage: "Messaggio aggiornato con successo",
            })
          );
          stopLoading();
          history.push(
            MANAGER_ROUTES.MESSAGES_CAMPAIGNS.replace(":campaignId?/", "")
          );
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
          stopLoading();
        });
    } else {
      return bmapi
        .saveMessageCampaign(
          values.title,
          values.body,
          values.banner,
          values.banner_close_mode,
          values.banner_close_mode === "DAY" ? values.banner_close_date : "",
          values.banner_close_mode === "DAY" ? 0 : +values.banner_close_number,
          values.push_notification,
          values.expire_at instanceof Date
            ? values.expire_at.toISOString().substring(0, 10)
            : values.expire_at,
          values.app_link_label,
          values.app_link_uri,
          values.image_url,
          values.link_uri,
          campaign_id === "-" ? "" : campaign_id,
          client_id
        )
        .then(() => {
          notifySuccess(
            intl.formatMessage({
              id: "pages.messageCampaign.create",
              defaultMessage: "Messaggio creato con successo",
            })
          );
          history.push(
            MANAGER_ROUTES.MESSAGES_CAMPAIGNS.replace(":campaignId?/", "")
          );
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
          //stopLoading();
        })
        .finally(stopLoading);
    }
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const findListName = (listId) =>
    lists.find((l) => l.id === listId)?.name || "";
  const findListCount = (listId) => (listId === "DIR-MARKETING" ? 6347 : 12492);

  const handleSubmitDistr = async (e) => {
    if (!distrValues.listId) {
      notifyError("Specificare la lista di distribuzione");
      return;
    }
    e.preventDefault();
    startLoading();
    await delay(2000);
    stopLoading();
    setDistributions((d) => [
      ...d,
      { listId: distrValues.listId, time: new Date() },
    ]);
  };

  return !businessId ? (
    <Container maxWidth="md" className="tablistNone"></Container>
  ) : campaigns ? (
    <Container maxWidth="sm" className="tablistNone">
      <Title>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box>
            <FormattedMessage
              id="pages.messageCampaigns.edit"
              defaultMessage="Modifica Messaggio"
            />
          </Box>
        </Box>
      </Title>
      <Box>
        <FormSection>
          <TextField
            disable={messageStatus !== 0}
            select
            label={intl.formatMessage(common.campaign)}
            value={campaignSelected}
            onChange={handleChangeCampaign}
            InputLabelProps={{
              shrink: true,
            }}
          >
            {campaigns
              .filter((s) => s.isActive)
              .map((option) => (
                <MenuItem key={option.name} value={option.campaign_id}>
                  {option.name}
                </MenuItem>
              ))}
          </TextField>
        </FormSection>
      </Box>
      <form onSubmit={handleSubmit}>
        <FormSection>{fields()}</FormSection>
        <Button variant="contained" fullWidth color="primary" type="submit">
          {intl.formatMessage(common.save)}
        </Button>
      </form>

      <Container maxWidth="sm" className="tablistNone">
        <Title>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
          >
            <Box>Distribuzione</Box>
          </Box>
        </Title>
        <form onSubmit={handleSubmitDistr}>
          <FormSection>
            <TextField
              select
              label="Lista di sitribuzione"
              value={distrValues.listId}
              onChange={handleDistrChange("listId")}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              required
            >
              {lists.map((l) => (
                <MenuItem key={l.id} value={l.id}>
                  {l.name}
                </MenuItem>
              ))}
            </TextField>
          </FormSection>
          <Button variant="contained" fullWidth color="primary" type="submit">
            Invia Distribuzione
          </Button>
        </form>
      </Container>

      {distributions?.length > 0 && (
        <Card>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Data</TableCell>
                  <TableCell>Lista</TableCell>
                  <TableCell>Numero destinatari</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {distributions?.map((d, i) => (
                  <TableRow key={"distr" + i}>
                    <TableCell>{format(d.time, "dd/MM/yyyy")}</TableCell>
                    <TableCell>{findListName(d.listId)}</TableCell>
                    <TableCell>{findListCount(d.listId)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      )}

      <Navigation />
    </Container>
  ) : null;
}
